var ie = 0;

$(function() {
    $( "tbody.sortable" ).sortable({
        handle: '.handle',
        update: function(event, ui) {
            ui.item.find('i.fa').removeClass('fa-bars').addClass('fa-spinner fa-spin');
            position = ui.item.index()+1;
            url = ui.item.parent().data('url');
            $.ajax({
                type: 'GET',
                url: url+'/'+ui.item.attr('id')+'/'+position
            }).done(function() {
                ui.item.find('i.fa').removeClass('fa-spinner fa-spin').addClass('fa-bars');
            }).fail(function() {
                alert( "Error while trying to save the new position. Please refresh page." );
            });
        }
    });

    $('.clone').on('click', function(e) {
        e.preventDefault();
        cloneTemplate($(this));
    });

    $(document).on('click','.removeClone', function(e) {
        e.preventDefault();
        $(this).closest('.cloned').remove();
        $(document).trigger( "clone:removed" );
    });

    $(document).on('keyup','.limitWords', function(e) {
        enforceWordLimit($(this));
    });

    $(document).on('change','.limitWords', function(e) {
        enforceWordLimit($(this));
    });

    $('.limitWords').each(function( index ) {
        left = wordsLeft($(this));
        $("label[for='"+$(this).attr("id")+"'] .left").html(left);

    });

    $(document).on('keyup','.countWords', function(e) {
        count = countWords($(this));
    });

    $(document).on('change','.countWords', function(e) {
        count = countWords($(this));
    });

    $('.countWords').each(function( index ) {
        count = countWords($(this));

    });

    $(window).scroll(function () {
        if ($(this).scrollTop() > 50) {
            $('#back-to-top').fadeIn();
        } else {
            $('#back-to-top').fadeOut();
        }
    });
    // scroll body to 0px on click
    $('#back-to-top').click(function () {
        $('#back-to-top').tooltip('hide');
        $('body,html').animate({
            scrollTop: 0
        }, 800);
        return false;
    });

    $('#back-to-top').tooltip('show');

});

function cloneTemplate(cloner){
    count = cloner.data("count");
    template = $.trim($('#'+cloner.data("template")).html());
    newItemHtml = template.replace(/::count/g, count);
    target = $(cloner.data("target"));
    target.append(newItemHtml);
    cloner.data("count",count+1);
}

function enforceWordLimit(field){
    max = wordsMax(field);
    left = wordsLeft(field);

    $("label[for='"+field.attr("id")+"'] .left").html(left);

    if(left <0 ) {
        limited = $.trim(text).split(/[\s\n\r]+/, max);
        limited = limited.join(" ");
        text = text.substring(0,limited.length);
        field.val(text);
    }
}

function wordsMax(field){
    if(field.data('max-words')){
        max = field.data('max-words');
    }
    else{
        max = 100;
    }
    return max;
}

function wordsLeft(field){
    text = field.val();
    max = wordsMax(field);
    if(text === "") {
        wordcount = 0;
    } else {
        wordcount = $.trim(text).split(/[\s\n\r]+/).length;
    }
    left = max-wordcount;
    return left;
}

function countWords(field){
    text = field.val();
    if(text === "") {
        wordcount = 0;
    } else {
        wordcount = $.trim(text).split(/[\s\n\r]+/).length;
    }
    $("label[for='"+field.attr("id")+"'] .count").html(wordcount);
    countTotal();
    return wordcount;
}

function countTotal(){
    total = 0;
    $('.count').each(function( index ) {
        total += parseInt($(this).html());
    });
    if(total>wordLimit) {
        $('#totalWarning').addClass('alert-danger');
        $('#totalWarning').removeClass('alert-warning');
    }
    else{
        $('#totalWarning').removeClass('alert-danger');
        $('#totalWarning').addClass('alert-warning');
    }
    $('.total').html(total);
}
